import Vue from "vue";

/*TITLE*/
document.title = "Jardines de San Luis | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Jardines de San Luis";
Vue.prototype.$subtitle = "";

/*INTRO*/
Vue.prototype.$promoter = "Jardines de San Luis";
Vue.prototype.$introSubtitle = "";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_residence-utah--20230227030201.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo-1_vela-natural_limit-vela-natural-v2--20230227030245.jpg";
Vue.prototype.$image_bath2 = "banyo-2_vela-natural_limit-vela-natural--20230227030229.jpg";
Vue.prototype.$image_room = "dormitorio_residence-utah--20230301050348.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_residence-utah--20230227030201.jpg",
  },
  {
    src: "salon_residence-nevada--20230227030207.jpg",
  },
  {
    src: "salon_residence-idaho--20230227030212.jpg",
  },
  {
    src: "salon_residence-arizona--20230227030218.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-1_vela-natural_limit-vela-natural-v2--20230227030245.jpg",
  },
  {
    src: "banyo-1_vela-natural_limit-vela-natural--20230227030250.jpg",
  },
  {
    src: "banyo-1_urban-natural_cubik-urban-natural-v2--20230227030257.jpg",
  },
  {
    src: "banyo-1_urban-natural_cubik-urban-natural--20230227030201.jpg",
  },
  {
    src: "banyo-1_matika-silver_stripe-matika-silver-v2--20230227030206.jpg",
  },
  {
    src: "banyo-1_matika-silver_stripe-matika-silver--20230227030212.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2_vela-natural_limit-vela-natural--20230227030229.jpg",
  },
  {
    src: "banyo-2_urban-natural_cubik-urban-natural--20230227030234.jpg",
  },
  {
    src: "banyo-2_matika-silver_stripe-matika-silver--20230227030240.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "dormitorio_residence-utah--20230301050348.jpg",
  },
  {
    src: "dormitorio_residence-nevada--20230301050353.jpg",
  },
  {
    src: "dormitorio_residence-idaho--20230301050357.jpg",
  },
  {
    src: "dormitorio_residence-arizona--20230301050302.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/jardinesdesanluis/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = false;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20curro%20romero%201%2019161%20pozo%20de%20guadalajara%20guadalajara",
    text: "C/ Curro Romero 1,19161 - Pozo de Guadalajara, Guadalajara",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20mayor%2029%20local%202%2019161%20pozo%20de%20guadalajara%20guadalajara",
    text: "C/ Mayor 29, local 2, 19161 - Pozo de Guadalajara, Guadalajara",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:617138532/607517227",
    text: "617138532/607517227",
  },
  {
    icon: "mdi-email",
    link: "mailto:hola@jardinesdesanluis.es",
    text: "hola@jardinesdesanluis.es",
  },
];
